
.swiper-container{
    position: static;
    height:100%;
}
.swiper-button-prev, .swiper-button-next{
    width: 26px;
    height: 80px;
    border-radius: 12px;
    border: solid 0.5px #d3d5db;
    background-color: #fff;
    
    top:0px;
    --swiper-theme-color: black;
    --swiper-navigation-size: 20px ;
}
@media screen and (max-width: 1020px){
    .swiper-button-prev, .swiper-button-next{
        top:0px;
    }
}
.swiper-button-prev{
    left: -24px;

}
.swiper-button-next{
    right: -24px;
}
.banner-swiper .swiper-container{
    height:100%;
    border-radius: 20px;
}
.banner-swiper .swiper-slide{
    margin: 0px;
}
.react-reveal{
    width:100%;
}