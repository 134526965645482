// Global Css Name 설정 필수

// .rbc-month-row{
//     width:5px;
//     height: 5px;

//     border-radius: 100%;
// }
.myCustomMobile .rbc-month-row{
    min-height: 70px;
}
.myCustomMobile .rbc-row-bg{
    height: 70px;
}
.myCustomMobile .rbc-month-header{
    height: 46px;
}
.myCustomMobile .rbc-day-bg{
    width: 135px;
    height: 70px;
    background-color: white;
    padding: 10px;
}
.myCustomMobile .rbc-date-cell{
    margin-top:6px;
}
.myCustomMobile .rbc-month-view{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    
}
.ltr-support .myCustomMobile .rbc-date-cell a{
    padding-right: 4px;

    font-size: 14px;
}
.myCustomMobile .rbc-off-range{
    color: #babdc4;
    opacity:1;
}
.myCustomMobile .rbc-header{
    color: #000000;
    font-size: 16px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.theme-light .myCustomMobile .rbc-header{
    background-color: transparent;
}

.theme-light .myCustomMobile .rbc-toolbar-label{
    color: #000;
    font-size: 24px;
}
.myCustomMobile{
    margin-bottom: 0px;
}
.theme-light .myCustomMobile .rbc-toolbar button{
    background-color: white;

}
.myCustomMobile .rbc-toolbar{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-right: 21px;
    margin-bottom: 12px;
}
.myCustomMobile .rbc-btn-group > button:nth-child(1){
    display:none;
}
.myCustomMobile .rbc-btn-group > button:nth-child(2){
    position: absolute;
    left: 4px;
    bottom:0px;
    top:0px;
}
.myCustomMobile .rbc-btn-group > button:nth-child(3){
    position: absolute;
    right: 0px;
    bottom:0px;
    top:0px;
}

.myCustomMobile .rbc-calendar{
    height: 100%;
}

.theme-light .myCustomMobile .rbc-toolbar button{
    width:33px;
    height: 33px;
    border-radius: 100%;

    padding: 0px 0px 0px 2px;
}
.myCustomMobile .rbc-off-range-bg{
    background: #ffffff;
}
.myCustomMobile .rbc-event-content{
    display:none;
}
.myCustomMobile .rbc-row-content .rbc-date-cell:nth-child(1){
    color: red;
}
.myCustomMobile .rbc-off-range {
    color: #babdc4 !important;
}
.rbc-show-more{
    display:none;
}

.myCustomMobile .rbc-row-content .rbc-row:nth-child(n+3) {
    display:none;
}