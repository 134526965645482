// Global Css Name 설정 필수

// .rbc-month-row{
//     width:5px;
//     height: 5px;

//     border-radius: 100%;
// }
.calendar .rbc-event-content{
    height: 5px;
    z-index: 200 !important;
}
.calendar .rbc-month-row{
    min-height: 45px;
}
.rbc-row-bg{
    height: 45px;
}

.calendar .rbc-day-bg{
    width: 37px;
    height: 100%;
    border-radius: 8px;
    margin: 0px 4px;
    // background-color: blue;
    // z-index: 100 !important;
    // opacity: 0;
}

// .calendar .rbc-day-bg:hover {
//     background-color: #d3d3d3;
//     opacity: 0.5;
// }

 .ltr-support .calendar .rbc-date-cell a{
    padding-left: 15.5px;
}

.rbc-off-range{
    opacity:0.4;
}
.calendar .rbc-header{
    color: #000000;
}
.theme-light .calendar .rbc-header{
    background-color: transparent;
}
.ltr-support .calendar .rbc-header + .rbc-header, .calendar .rbc-header + .rbc-header, .rbc-month-view,.ltr-support .calendar .rbc-day-bg + .rbc-day-bg, .rbc-month-row + .rbc-month-row , .rbc-header{
    border: 0px;
}
.theme-light .calendar .rbc-toolbar-label{
    color: #000;
    font-size: 20px;
}
.calendar{
    margin-bottom: 0px;
}
.theme-light .calendar .rbc-toolbar button{
    background-color: white;

}
.rbc-toolbar{
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-right: 21px;
}
.rbc-btn-group > button:nth-child(2){
    position: absolute;
    left: 4px;
    bottom:0px;
    top:0px;
}
.rbc-btn-group > button:nth-child(3){
    position: absolute;
    right: 0px;
    bottom:0px;
    top:0px;
}
.rbc-calendar{
    height: 315px;
}

.calendar .rbc-now{
    & > a{
        font-weight: bold !important;
    }
}
.rbc-show-more{
    display:none;
}

.calendar .rbc-row-content .rbc-row:nth-child(n+3) {
    display:none;
}
.calendar.calendar--small{
    height: 330px;
}