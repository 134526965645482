.selected{
    &::after{
        width : 58px;
        height: 1.5px;
        background-color: #36aa3a;
        content: " ";

        position: absolute;
        bottom: 16px;
        left:0px;
        right:0px;

        margin: 0 auto;
    }
}

.nav1{
    &:hover{
        & ~ .navigation_wrapper > .navigation > div:nth-child(1){
		    background-color: #36aa3a;
		}
    }
}
.nav2{
    &:hover{
        & ~ .navigation_wrapper > .navigation > div:nth-child(2){
		    background-color: #36aa3a;
		}
    }
}
.nav3{
    &:hover{
        & ~ .navigation_wrapper > .navigation > div:nth-child(3){
		    background-color: #36aa3a;
		}
    }
}
.nav4{
    &:hover{
        & ~ .navigation_wrapper > .navigation > div:nth-child(4){
		    background-color: #36aa3a;
		}
    }
}
.nav5{
    &:hover{
        & ~ .navigation_wrapper > .navigation > div:nth-child(5){
            background-color: #36aa3a;
		}
    }
}