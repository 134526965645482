// Global Css Name 설정 필수

// .rbc-month-row{
//     width:5px;
//     height: 5px;

//     border-radius: 100%;
// }
.myCustomHeight .rbc-month-row{
    min-height: 135px;
}
.myCustomHeight .rbc-row-bg{
    height: 135px;
}
.myCustomHeight .rbc-month-header{
    height: 50px;
}
.myCustomHeight .rbc-day-bg{
    width: 135px;
    height: 135px;
    background-color: white;
    padding: 10px;
}
.myCustomHeight .rbc-date-cell{
    margin-top:10px;
}
.myCustomHeight .rbc-month-view{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    
}
.ltr-support .myCustomHeight .rbc-date-cell a{
    padding-right: 6px;
    padding-top: 6px;
    font-size: 16px;
}
.myCustomHeight .rbc-off-range{
    color: #babdc4;
    opacity:1;
}
.myCustomHeight .rbc-header{
    color: #000000;
    font-size: 16px;

    display:flex;
    justify-content: center;
    align-items: center;
}
.myCustomHeight .rbc-event-content{
    width: calc( 100% - 12px );
    height:100%;
    margin: 0 0 0 auto;
    padding: 6px 4px 6px 8px;

    background-color: white;
    color: black;

    display: flex;
    align-items:center;

    white-space: normal;

    font-size: 11px;
    line-height: 1.2;
    word-break: keep-all;
}
.theme-light .myCustomHeight .rbc-header{
    background-color: transparent;
}

.theme-light .myCustomHeight .rbc-toolbar-label{
    color: #000;
    font-size: 24px;
}
.myCustomHeight{
    margin-bottom: 0px;
}
.theme-light .myCustomHeight .rbc-toolbar button{
    background-color: white;

}
.myCustomHeight .rbc-toolbar{
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-right: 21px;
    margin-bottom: 12px;
}
.myCustomHeight .rbc-btn-group > button:nth-child(1){
    display:none;
}
.myCustomHeight .rbc-btn-group > button:nth-child(2){
    position: absolute;
    left: 4px;
    bottom:0px;
    top:0px;
}
.myCustomHeight .rbc-btn-group > button:nth-child(3){
    position: absolute;
    right: 0px;
    bottom:0px;
    top:0px;
}

.myCustomHeight .rbc-calendar{
    height: 100%;
}

.theme-light .myCustomHeight .rbc-toolbar button{
    width:33px;
    height: 33px;
    border-radius: 100%;

    padding: 0px 0px 0px 2px;
}
.myCustomHeight .rbc-off-range-bg{
    background: #ffffff;
}
.myCustomHeight .rbc-row-content .rbc-date-cell:nth-child(1){
    color: red;
}
.myCustomHeight .rbc-off-range {
    color: #babdc4 !important;
}
.myCustomHeight .rbc-row-content .rbc-row{
    &:nth-child(4){
        display:none;
    }
}