
img{
    width:fit-content;
    height:fit-content;
}

p{
    margin:0px;
    padding:0px;
}

* {font-family: "SUIT", sans-serif !important} 