.note-editable{
    background-color: white;
}

label{
    margin:0px;
}

.note-editor{
    max-width: 673px;
}